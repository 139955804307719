// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-partner-carriers-index-js": () => import("/vercel/workpath2/website/src/templates/__partner-carriers/index.js" /* webpackChunkName: "component---src-templates-partner-carriers-index-js" */),
  "component---src-templates-style-sheet-index-js": () => import("/vercel/workpath2/website/src/templates/__style-sheet/index.js" /* webpackChunkName: "component---src-templates-style-sheet-index-js" */),
  "component---src-templates-freight-index-js": () => import("/vercel/workpath2/website/src/templates/freight/index.js" /* webpackChunkName: "component---src-templates-freight-index-js" */),
  "component---src-templates-fulfillment-index-js": () => import("/vercel/workpath2/website/src/templates/fulfillment/index.js" /* webpackChunkName: "component---src-templates-fulfillment-index-js" */),
  "component---src-templates-index-index-js": () => import("/vercel/workpath2/website/src/templates/index/index.js" /* webpackChunkName: "component---src-templates-index-index-js" */),
  "component---src-templates-warehousing-index-js": () => import("/vercel/workpath2/website/src/templates/warehousing/index.js" /* webpackChunkName: "component---src-templates-warehousing-index-js" */),
  "component---src-templates-why-tugg-index-js": () => import("/vercel/workpath2/website/src/templates/why-tugg/index.js" /* webpackChunkName: "component---src-templates-why-tugg-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/vercel/workpath2/website/.cache/data.json")

